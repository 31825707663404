import Resource from '@/api/resource';
import request from '@/utils/request';

class UserResource extends Resource {
  constructor() {
    super('user');
  }

  info() {
    return request({
      url: '/info',
      method: 'get',
    });
  }

  table() {
    return request({
      url: '/user/table',
      method: 'get',
    });
  }

  profile() {
    return request({
      url: '/profile',
      method: 'get',
    });
  }

  childs(data) {
    return request({
      url: '/user/childs',
      method: 'get',
      params: data,
    });
  }

  new(data) {
    return request({
      url: '/newuser',
      method: 'post',
      params: data,
    });
  }

}

export { UserResource as default };
